<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="roleList-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="() => addEdit()"
      >新增角色</el-button
    >
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="name"
        label="角色名称"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="num" label="数量" :formatter="tableColumn">
        <template slot-scope="scope">
          <!-- <el-popover
            placement="top-start"
            title="标题"
            trigger="click"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
          > -->
          <el-button
            slot="reference"
            type="text"
            @click="cellClick(scope.row)"
            >{{ scope.row.num }}</el-button
          >
          <!-- </el-popover> -->
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="角色描述"
        :formatter="tableColumn"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createBy"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="200px" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.canEdit != '1'"
            type="text"
            size="small"
            class="editbtn"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.canEdit == '1'"
            @click="() => addEdit(scope.row)"
            type="text"
            size="small"
            class="editbtn2"
            >修改</el-button
          >
          &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            class="delbtn"
            v-if="scope.row.canEdit == '1'"
            placement="top"
            title="确定删除吗？"
            @confirm="() => deleteItem(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
          <el-button
            v-if="scope.row.canEdit != '1'"
            type="text"
            size="small"
            class="delbtn2"
            >删除</el-button
          >
          <!-- <el-button
            v-if="scope.row.canEdit != '1'"
            type="text"
            size="small"
            style="color: #aaa; margin-left: 10px"
            >权限设置</el-button
          > -->
          <el-button
            @click="() => menuSetUp(scope.row.id, scope.row.canEdit)"
            type="text"
            size="small"
            class="canedit"
            >权限设置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      :title="tableRowId ? '修改角色' : '添加角色'"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleFormAdd"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <div class="dialog-tip">角色名称不可重复</div>
          <el-form-item label="角色名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.name"
              placeholder="请填写角色名称"
              maxLength="50"
            ></el-input>
          </el-form-item>

          <el-form-item label="角色描述" prop="description">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.description"
              maxLength="50"
              placeholder="请填写角色描述"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <role-setup
      ref="menuSetUp"
      :canEdit="canEdit"
      :drawer="drawer"
      @handleClose="handleClose"
    ></role-setup>
    <el-dialog
      v-dialogDrag
      title="详情"
      width="75%"
      :visible.sync="numDialogVisible"
      :append-to-body="true"
    >
      <el-table class="common-table" :data="tableData2">
        <el-table-column
          prop="account"
          label="账号ID"
          :formatter="tableColumn"
          width="170px"
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="员工名称"
          :formatter="tableColumn"
          width="160px"
        ></el-table-column>
        <el-table-column
          prop="roleName"
          label="员工角色"
          :formatter="tableColumn"
          width="170px"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属分公司"
          :formatter="tableColumn"
          width="170px"
        ></el-table-column>
        <el-table-column
          prop="userDepartName"
          label="所属部门"
          :formatter="tableColumn"
          width="160px"
        ></el-table-column>
        <el-table-column
          prop="joinTime"
          label="入职时间"
          :formatter="tableColumn"
          width="160px"
        ></el-table-column>
        <!-- <el-table-column prop="no" label="标签" :formatter="tableColumn"> </el-table-column> -->
        <el-table-column
          prop="createName"
          label="创建人"
          :formatter="tableColumn"
          width="160px"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          :formatter="tableColumn"
          width="200px"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="(e) => pageFun2(e, 'pageSize')"
        @current-change="(e) => pageFun2(e)"
        style="text-align: center"
        :page-sizes="pagination2.pageSizes"
        :page-size="params2.pageSize"
        :current-page="params2.page"
        layout="total, prev, pager, next,sizes, jumper"
        :total="pagination2.total"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="numDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="numDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import RoleSetup from "./children/roleListSetUp.vue";
import { tableColumn,Config } from "../../utils/index.js";
import {
  getRoleList,
  addRole,
  editRole,
  deleteRole,
  getUserList
} from "../../service/manage.js";
export default {
  components: {
    Breadcrumb,
    RoleSetup
  },
  data() {
    return {
      tableColumn,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "组织", isLink: false },
        { title: "角色列表", isLink: false }
      ],
      tableRowId: "", // 编辑id
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10
      },
      drawer: false, // 设置权限弹窗
      centerDialogVisible: false, // 新增修改角色弹窗
      ruleFormAdd: {
        name: "",
        description: ""
      },
      canEdit: "0",
      rules: {
        name: [{ required: true, message: "请填写角色名称", trigger: "change" }]
      },
      tableData: [],
      params2: {
        pageNum: 1,
        pageSize: 10
      },
      pagination2: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      tableData2: [],
      numDialogVisible: false,
      rolesCompany: this.$sto.get(Config.constants.userInfo).company_id // 所属公司
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {
    menuSetUp(id, canEdit) {
      // 权限设置
      this.canEdit = canEdit;
      this.$refs["menuSetUp"].getRoleMenu(id, canEdit);
      this.drawer = true;
    },
    submitForm(formName) {
      // 新增修改提交
      let tableRowId = this.tableRowId;
      let ruleFormAdd = this.ruleFormAdd;
      ruleFormAdd.companyId = this.rolesCompany
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (tableRowId) {
            let res = await editRole(ruleFormAdd, tableRowId);
            if (res.code == 200) {
              this.$message.success("修改成功");
            }
          } else {
            let res = await addRole(ruleFormAdd);
            if (res.code == 200) {
              this.$message.success("添加成功");
            }
          }
          this.params.pageNum = 1;
          this.params.pageSize = 10;
          this.centerDialogVisible = false;
          this.getRoleList();
        } else {
          return false;
        }
      });
    },
    async addRole() {
      // 添加角色/修改角色
      await addRole(this.ruleFormAdd);
    },
    async getRoleList() {
      // 获取角色列表
      this.loading = true;
      let resData = (await getRoleList(this.params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.loading = false;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getRoleList();
    },
    addEdit(row) {
      // 添加修改角色弹窗
      if (row) {
        this.tableRowId = row.id;
        this.ruleFormAdd = {
          name: row.name,
          description: row.description
        };
      } else {
        this.tableRowId = "";
        this.ruleFormAdd = {
          name: "",
          description: ""
        };
        if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      }
      this.centerDialogVisible = true;
    },
    async deleteItem(id) {
      // 删除角色
      await deleteRole({}, id);
      this.$message.success("删除成功");
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getRoleList();
    },
    handleClose(open) {
      // 打开/关闭新增员工弹窗
      if (!open) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
      // done();
    },
    pageFun2(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params2.pageSize = e;
      } else {
        this.params2.pageNum = e;
      }
      this.getTableData();
    },
    async getTableData() {
      // 获取表格数据
      this.loading = true;
      let params = { ...this.params2 };
      if (typeof params.userDepartId == "object") {
        delete params.userDepartId;
      }

      let resData = (await getUserList(params)).data;

      this.pagination2.total = resData.total;
      this.pagination2.pageSize = resData.size;
      this.tableData2 = resData.records;
      // let tableData2Name = [];
      // this.tableData2.forEach((item) => {
      //   tableData2Name.push(item.nickName);
      // });
      // console.log(tableData2Name, "nickName");
      this.loading = false;
    },
    cellClick(row) {
      this.params2.roleId = row.id;
      this.params2.companyId = this.rolesCompany;
      this.getTableData();
      this.numDialogVisible = true;
    }
  }
};
</script>
<style lang="less" scoped>
.roleList-container {
  text-align: left;
}
.dialog-input {
  width: 320px !important;
}
.dialog-tip {
  font-size: 12px;
  color: #00000073;
  margin-left: 100px;
}
.editbtn {
  color: #aaa;
  margin-left: 10px;
}
.editbtn2 {
  margin-left: 10px;
}
.delbtn {
  margin-left: 10px;
}
.delbtn2 {
  color: #aaa;
  margin-left: 10px;
}
.canedit {
  margin-left: 10px;
}
</style>
